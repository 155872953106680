
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import type { Consent, UserSettings } from '@/stores/user.store';

import VerifyEmailBannerDataProvider from '@/components/modals/account/VerifyEmailBannerDataProvider.vue';
import { initTrackers } from '@/main.common';

const ConsentBanner = () => import(/* webpackChunkName: "consent-banner" */ '@/components/consent/ConsentBanner.vue');
const ListsForSignedInUsersOnboarding = () =>
	import(/* webpackChunkName: "consent-banner" */ '@/components/modals/ListsForSignedInUsersOnboarding.vue');
const ConsentOverlay = () =>
	import(/* webpackChunkName: "consent-overlay" */ '@/components/consent/ConsentOverlay.vue');
const FilterMenu = () => import(/* webpackChunkName: "filter-menu" */ '@/components/filter-bar/FilterMenu.vue');
const MobileBanner = () => import(/* webpackChunkName: "mobile-banner" */ '@/components/MobileBanner.vue');

import { CollectionType } from '@/enums/collection-type';
import type { WebLocale } from '@/enums/web-locale';

import { logTalentAd } from '@/helpers/ad/talent-ad';
import { TrackingHelper } from '@/helpers/tracking/tracking-helper';

import { isSameWeek } from '@/helpers/date-helper';
import { updateUserProfile } from '@/helpers/id-helper';
import { initSentry } from '@/helpers/sentry-helper';
import { hasUCConsentBanner } from '@/helpers/tracking';
import { isMobile } from '@/helpers/ui-helper';
import { isArbitrating } from '@/helpers/composables/useArbitrage';
import TitleRemovalBanner from '@/components/banners/TitleRemovalBanner.vue';
import { lowPriorityCallback } from '@/helpers/request-idle-helper';

const Language = namespace('language');
const Routing = namespace('routing');
const User = namespace('user');

@Component({
	components: {
		ConsentBanner,
		FilterMenu,
		ListsForSignedInUsersOnboarding,
		MobileBanner,
		VerifyEmailBannerDataProvider,
		TitleRemovalBanner,
	},
})
export default class LowPriorityComponents extends Vue {
	shouldRenderMenu = false;

	@Language.Getter country: string;
	@Language.Getter language: string;
	@Language.State webLocale: WebLocale;

	@Routing.Getter lastActiveCollectionType: null | CollectionType;
	@Routing.Getter activeCollectionType: null | CollectionType;

	@User.Action saveSettings: (partialSettings: Partial<UserSettings>) => Promise<void>;
	@User.Action updateUserVisitStatus: () => void;
	@User.Getter isLoggedIn: boolean;

	@User.State jwId: string;
	@User.State lastTimeMobileAppBannerWasClosedTS: number;

	// it shouldn't go back to `false` anymore, so the filter menu is still available even after page navigations to the title detail.
	@Watch('activeCollectionType', { immediate: true })
	onActiveCollectionChange() {
		const { activeCollectionType } = this;
		if (
			!this.shouldRenderMenu &&
			!!activeCollectionType &&
			[
				CollectionType.NEW,
				CollectionType.POPULAR,
				CollectionType.SEARCH,
				CollectionType.MY_LISTS,
				CollectionType.TV_SHOW_TRACKING,
				CollectionType.PUBLIC_LISTS,
				CollectionType.TITLELIST,
			].includes(activeCollectionType)
		) {
			this.shouldRenderMenu = true;
		}
	}

	get consentComponent() {
		if (!global.VUE_APP_TESTS_E2E && hasUCConsentBanner(this.webLocale)) {
			return ConsentOverlay;
		}

		return ConsentBanner;
	}

	get showMobileAppBanner() {
		return (
			!this.isLoggedIn &&
			!isSameWeek(new Date(this.lastTimeMobileAppBannerWasClosedTS), new Date()) &&
			!isArbitrating.value
		);
	}

	mounted() {
		if (process.env.NODE_ENV !== 'development') {
			logTalentAd();
		}

		lowPriorityCallback(this, () => initTrackers(this.$store));
		lowPriorityCallback(this, () => initSentry());
		this.updateUserVisitStatus();

		if (isMobile()) {
			// @ts-ignore
			import('long-press-event/dist/long-press-event.min.js');
		}

		// NOTE This is only for testing (otherwise we have no way to force old consents)
		(window as any).saveSettings = async (consents: Record<Consent, boolean>) => {
			await this.saveSettings({ jw_consents: consents });
		};

		if (this.country) {
			try {
				updateUserProfile(this.jwId, this.country, this.language);
			} catch (error: any) {
				TrackingHelper.trackEvent('app_error', {
					action: 'update_user_profile_failure',
					label: error,
				});
			}
		}
	}
}
